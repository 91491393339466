<template>
  <v-hover v-slot="{ hover }">
    <v-btn 
      @click="
        $emit('clicked')
        url ? $router.push({ name: url, params }) : $router.go(-1)
      " 
      class="mr-3 rounded" 
      color="grey lighten-3" 
      width="30"
      height="30"
      outlined
      fab 
    >
      <v-icon :color="hover ? 'primary' : 'grey'" :size="hover ? '27' : '25'">mdi-chevron-left</v-icon>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  props: {
    url: String,
    params: Object,
  }
}
</script>